import { IconButton } from "@chakra-ui/button";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { Container, Flex, Heading } from "@chakra-ui/layout";
import { CLIEngine } from "eslint";
import { navigate } from "gatsby-link";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";
import FileDrawer from "../fileDrawer";
import SEO from "../seo";

export default function Blog({ pageContext }) {
  const { frontmatter, body, slug } = pageContext.post;
  const { title, omitBackButton, description } = frontmatter;
  console.log(description);
  return (
    <>
      <SEO isBlog slug={`/${slug}`} title={title} description={description} />
      <Flex alignItems="flex-start">
        {/* <FileDrawer alignSelf="start" /> */}

        <Container my="4rem" maxW="3xl">
          {!omitBackButton && (
            <IconButton
              aria-label="go back"
              variant="ghost"
              icon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
              mb="1rem"
            />
          )}
          <Heading variant="blogTitle">{title}</Heading>
          <MDXRenderer>{body}</MDXRenderer>
        </Container>
      </Flex>
    </>
  );
}
